import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import cx from "classnames";

import BaseNumberInput from "../../components/Inputs/BaseNumberInput";
import OptionsGroup from "../../components/OptionsGroup";
import { yesNoOptions } from "../../../../commons/loan-application-constants";
import { useStyles as useStepStyles } from "../../../../utils/loan-application.styles";
import Button from "../../components/Button";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  marginTop48OnMobile: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "48px",
    },
  },
}));

const LoanRetirementPlanStep = ({
  formik,
  handleSaveSection,
  handlePrevStep,
}) => {
  const classes = useStyles();
  const stepClasses = useStepStyles();

  useEffect(() => {
    formik.setFieldTouched("loanQuiz.retirementAge", false);
  }, []);

  const onSaveAndNext = async () => {
    const errors = await formik.validateForm();
    formik.setFieldTouched("loanQuiz.retirementAge", true);
    if (errors?.loanQuiz?.retirementAge) {
      return;
    }
    await handleSaveSection();
  };

  return (
    <Box display="flex" flexDirection="column" gridRowGap="48px">
      <Box classes={{ root: classes.inputContainer }}>
        <BaseNumberInput
          label="What age are you plaining to retire?"
          maxValue={100}
          minValue={0}
          value={formik.values.loanQuiz.retirementAge}
          onChange={(value) =>
            formik.setFieldValue("loanQuiz.retirementAge", value)
          }
        />
        {formik.touched.loanQuiz?.retirementAge &&
          formik.errors.loanQuiz?.retirementAge && (
            <Typography variant="caption" color="error">
              {formik.errors.loanQuiz?.retirementAge}
            </Typography>
          )}
      </Box>
      <Grid container spacing={6} xs={12} sm={7}>
        <OptionsGroup
          title="Do you plan or anticipate changes (other than retirement) to your future financial circumstances that could ADVERSELY impact your ability to repay the loan?"
          options={yesNoOptions}
          selectedValue={formik.values.loanQuiz.hasRetirementPlan}
          onSelect={(value) =>
            formik.setFieldValue("loanQuiz.hasRetirementPlan", value)
          }
        />
      </Grid>
      <Grid
        container
        xs={12}
        sm={6}
        justifyContent="space-between"
        classes={{
          root: cx(
            stepClasses.navigationButtonsContainer,
            classes.marginTop48OnMobile
          ),
        }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>
        <Button endIcon={<NavigateNextIcon />} onClick={onSaveAndNext}>
          Save and Next
        </Button>
      </Grid>
    </Box>
  );
};

export default LoanRetirementPlanStep;
